import * as validators from '@vuelidate/validators'
import { i18n } from '~/plugins/i18n'

export function useValidators () {
  const withI18nMessage = validators.createI18nMessage({
    t: i18n.global.t.bind(i18n),
  })

  const required = withI18nMessage(validators.required)
  const requiredIf = withI18nMessage(validators.requiredIf, {
    withArguments: true,
  })
  const requiredUnless = withI18nMessage(validators.requiredUnless, {
    withArguments: true,
  })
  const minLength = withI18nMessage(validators.minLength, {
    withArguments: true,
  })
  const maxLength = withI18nMessage(validators.maxLength, {
    withArguments: true,
  })
  const minValue = withI18nMessage(validators.minValue, {
    withArguments: true,
  })
  const maxValue = withI18nMessage(validators.maxValue, {
    withArguments: true,
  })
  const between = withI18nMessage(validators.between, {
    withArguments: true,
  })
  const alpha = withI18nMessage(validators.alpha)
  const alphaNum = withI18nMessage(validators.alphaNum)
  const numeric = withI18nMessage(validators.numeric)
  const integer = withI18nMessage(validators.integer)
  const decimal = withI18nMessage(validators.decimal)
  const email = withI18nMessage(validators.email)
  const ipAddress = withI18nMessage(validators.ipAddress)
  const macAddress = withI18nMessage(validators.macAddress, {
    withArguments: true,
  })
  const sameAs = withI18nMessage(validators.sameAs, {
    withArguments: true,
  })
  const url = withI18nMessage(validators.url)
  const or = withI18nMessage(validators.or, { withArguments: true })
  const and = withI18nMessage(validators.and, { withArguments: true })
  const not = withI18nMessage(validators.not, { withArguments: true })

  return {
    url,
    or,
    and,
    not,
    sameAs,
    macAddress,
    ipAddress,
    email,
    decimal,
    integer,
    numeric,
    alpha,
    alphaNum,
    between,
    maxValue,
    minValue,
    maxLength,
    minLength,
    requiredUnless,
    requiredIf,
    required,
  }
}
